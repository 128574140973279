.experience__conatainer{
    display: grid;
    grid-template-columns:1fr 1fr;
    gap: 2rem;
}
.experience__conatainer > div{
    background: var(--color-bg-variant);
    padding: 2.5rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}
.experience__conatainer > div:hover{
    background: transparent;
    cursor: default;
    background-color: var(--color-primary-variant);
}
.experience__conatainer >div h3{
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary-variant);
}
.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.experience__details{
    display: flex;
    gap: 1rem;
}

@media screen and (max-width:1024px) {
    .experience__container{
        display: flex;
        flex-direction: column;
    }
    .experience__container > div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience__content{
        padding: 1rem;
    }
  
}

@media screen and (max-width:600px) {
   .experience__conatainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   }
   .experience__conatainer > div{
    width: 100%;
    padding: 2rem 1rem;
   }
}